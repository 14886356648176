import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookMessenger,
  faInstagram,
  faTelegram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAddressBook,
  faComments,
  faEnvelope,
  faSms,
} from '@fortawesome/free-solid-svg-icons';

const Base = ({ size, bg, children }) => (
  <div
    className={`mr-4 rounded-full h-${size} w-${size} flex self-center flex-shrink-0 items-center justify-center ${bg}`}
  >
    {children}
  </div>
);

const Whatsapp = ({ size }) => (
  <Base bg={'bg-green-600'} size={size}>
    <FontAwesomeIcon
      icon={faWhatsapp}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);

const Correo = ({ size }) => (
  <Base bg={'bg-red-400'} size={size}>
    <FontAwesomeIcon
      icon={faEnvelope}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);

const Telegram = ({ size }) => (
  <Base bg={'bg-blue-400'} size={size}>
    <FontAwesomeIcon
      icon={faTelegram}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);

const SMS = ({ size }) => (
  <Base bg={'bg-purple-400'} size={size}>
    <FontAwesomeIcon
      icon={faSms}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);

const Contacto = ({ size }) => (
  <Base bg={'bg-yellow-400'} size={size}>
    <FontAwesomeIcon
      icon={faAddressBook}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);
const ChatBox = ({ size }) => (
  <Base bg={'bg-indigo-300'} size={size}>
    <FontAwesomeIcon
      icon={faComments}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);

const Messenger = ({ size }) => (
  <Base bg={'bg-blue-500'} size={size}>
    <FontAwesomeIcon
      icon={faFacebookMessenger}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);

const Instagram = ({ size }) => (
  <Base bg={'bg-pink-600'} size={size}>
    <FontAwesomeIcon
      icon={faInstagram}
      size={'xl'}
      className={`fill-current text-white`}
    />
  </Base>
);

export const ChatIcon = ({ chat, size = 12 }) => {
  if (chat) {
    if (chat.external_user.source.name === 'Whatsapp')
      return <Whatsapp size={size} />;
    if (chat.external_user.source.name === 'Correo')
      return <Correo size={size} />;
    if (chat.external_user.source.name === 'Telegram')
      return <Telegram size={size} />;
    if (chat.external_user.source.name === 'SMS') return <SMS size={size} />;
    if (chat.external_user.source.name === 'Contacto')
      return <Contacto size={size} />;
    if (chat.external_user.source.name === 'ChatBox')
      return <ChatBox size={size} />;
    if (chat.external_user.source.name === 'Messenger')
      return <Messenger size={size} />;
    if (chat.external_user.source.name === 'Instagram')
      return <Instagram size={size} />;
  }
  return <Base bg={'bg-gray-900'} size={size} />;
};
