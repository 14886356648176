import { useEffect, useState } from 'react';
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/outline';
import { EmptyTable, TagsTable } from './TagsTable';
import { useTagApi } from '../../../hooks/useTagApi';
import { AddTagSidebar } from './AddTagSidebar';
import { EditTagSidebar } from './EditTagSidebar';
import { wait } from '../../../utils/wait';

const Header = ({ onBack, onAdd }) => (
  <div className="flex h-16 justify-between items-center bg-indigo-700 lg:bg-indigo-600 z-10">
    <div className="flex items-center">
      <button
        className={
          'p-2 ml-4 rounded-lg hover:bg-indigo-900 active:bg-indigo-900 lg:hidden'
        }
        onClick={onBack}
      >
        <ArrowLeftIcon className={'h-6 text-gray-100'} />
      </button>

      <h4 className="ml-4 text-2xl font-medium text-white">Etiquetas</h4>
    </div>
    <div className={'p-2'}>
      <button
        className={'p-2 rounded-lg hover:bg-indigo-900 active:bg-indigo-900'}
        onClick={onAdd}
      >
        <PlusIcon className={'h-6 text-gray-100'} />
      </button>
    </div>
  </div>
);

export const TagSettings = ({ onBack }) => {
  const tagsApi = useTagApi();
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setLoading(true);
    tagsApi
      .getAll()
      .then((response) => {
        setTags(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tagsApi]);

  const onShowAdd = async () => {
    if (showEdit) {
      setShowEdit(false);
      await wait(400);
    }
    setShowAdd((old) => !old);
  };

  const onSelect = async (tag) => {
    setSelected(tag);
    if (showAdd) {
      setShowAdd(false);
      await wait(400);
    }
    setShowEdit(true);
  };

  const onCreate = async ({ nombre, color }) => {
    const tag = await tagsApi.create({ nombre, color });
    setTags((old) => [...old, tag]);
  };

  const onEdit = async ({ id, nombre, color }) => {
    const tag = await tagsApi.edit({ id, nombre, color });
    setTags((old) =>
      [...old].map((t) => {
        if (t.id === id) return tag;
        return t;
      }),
    );
  };

  const onDelete = async (tag) => {
    if (tag.id === selected?.id) {
      setSelected('');
      setShowEdit(false);
    }
    await tagsApi.remove({ id: tag.id });
    setTags((old) => [...old].filter((t) => t.id !== tag.id));
  };

  return (
    <div className={'flex flex-col h-full'}>
      <Header onBack={onBack} onAdd={onShowAdd} />
      <div className={'flex-1 flex overflow-y-scroll'}>
        <div className={'flex-1 mt-10 mb-5 mx-5'}>
          {!loading ? (
            <TagsTable tags={tags} onSelect={onSelect} onDelete={onDelete} />
          ) : (
            <EmptyTable />
          )}
        </div>
        <AddTagSidebar onCreate={onCreate} show={showAdd} />
        <EditTagSidebar tag={selected} onEdit={onEdit} show={showEdit} />
      </div>
    </div>
  );
};
