import { LoadingIcon } from './LoadingIcon';

export const Loading = () => (
  <div className="flex flex-col h-screen items-center justify-center">
    <LoadingIcon className="animate-spin h-1/6 w-1/6 text-indigo-600" />
    <h1 className="animate-bounce text-3xl font-extrabold md:mt-10">
      Cargando...
    </h1>
  </div>
);
