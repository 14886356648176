import { useEffect, useLayoutEffect, useState } from 'react';

export const EditTagSidebar = ({ tag, onEdit, show }) => {
  const [id, setId] = useState('');
  const [nombre, setNombre] = useState('');
  const [color, setColor] = useState('');
  const [animation, setAnimation] = useState('');
  const [shown, setShown] = useState(false);

  useLayoutEffect(() => {
    if (tag) {
      setId(tag.id);
      setNombre(tag.name);
      setColor(tag.color);
    } else {
      setId('');
      setNombre('');
      setColor('');
    }
  }, [tag]);

  useEffect(() => {
    if (show) {
      setAnimation('animate__slideInRight');
      setShown(true);
    } else {
      setAnimation('animate__slideOutRight');
      setTimeout(() => setShown(false), 400);
    }
  }, [show]);

  if (!shown) return <></>;

  return (
    <div
      className={`flex flex-col right-0 z-10 h-full p-2 border-gray-200 border-l w-96 animate__animated animate__faster ${animation}`}
    >
      <div className={'border-b border-gray-200 mb-4'}>
        <h1 className={'font-medium text-gray-800 text-xl mb-2'}>
          Editar Etiqueta
        </h1>
      </div>

      <div className={'space-y-5'}>
        <div>
          <label
            htmlFor="nombre"
            className="block text-sm font-medium text-gray-700"
          >
            Nombre
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="name"
              id="nombre"
              className="shadow-sm focus:ring-indigo-500
              focus:border-indigo-500 block w-full
              sm:text-sm border-gray-300 rounded-md"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="color"
            className="block text-sm font-medium text-gray-700"
          >
            Color
          </label>
          <div className="mt-1">
            <input
              type="color"
              name="color"
              id="nombre"
              className="border-gray-300 w-full h-8 rounded-md overflow-hidden"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </div>
        </div>

        <button
          className="inline-flex justify-center
          py-2 px-4 border border-transparent shadow-sm
          text-sm font-medium rounded-md text-white
          bg-indigo-600 hover:bg-indigo-700 focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => onEdit({ id, nombre, color })}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};
