const { TrashIcon } = require('@heroicons/react/outline');

const HeaderCell = ({ name }) => (
  <th
    className={`px-6 py-3 text-left text-xs 
    font-medium text-gray-500 uppercase tracking-wider`}
  >
    {name}
  </th>
);

const Cell = ({ children, onClick }) => (
  <td
    className={`px-6 py-4 whitespace-nowrap ${onClick && 'cursor-pointer'}`}
    onClick={onClick}
  >
    {children}
  </td>
);

const SimpleCell = ({ value, onClick }) => (
  <Cell onClick={onClick}>
    <div className="text-sm font-medium text-gray-900">{value}</div>
  </Cell>
);

const ColorCell = ({ value, onClick }) => (
  <Cell onClick={onClick}>
    <div
      className={`px-3 py-0.5 rounded-lg h-8 w-16`}
      style={{ backgroundColor: value }}
    />
  </Cell>
);

const ButtonsCell = ({ usuario, onDelete }) => (
  <td className="flex flex-row justify-end items-center space-x-4 px-6 py-4 whitespace-nowrap">
    <button
      className="text-gray-400 hover:text-gray-800 h-6 px-2 cursor-pointer"
      onClick={() => onDelete(usuario)}
    >
      <TrashIcon className="h-6" />
    </button>
  </td>
);

export const EmptyTable = () => (
  <div className="shadow overflow-hidden">
    <table className="w-full">
      <thead className="border-b">
        <tr>
          <HeaderCell name="Nombre" />
          <HeaderCell name="Color" />
        </tr>
      </thead>
      <tbody className="bg-white">
        <tr className="animate-pulse bg-gray-100 h-96">
          <td />
          <td />
        </tr>
      </tbody>
    </table>
  </div>
);

export const TagsTable = ({
  tags = [],
  onSelect = () => {},
  onDelete = () => {},
}) => (
  <div className="shadow overflow-hidden">
    <table className="w-full">
      <thead className="border-b">
        <tr>
          <HeaderCell name="Nombre" />
          <HeaderCell name="Color" />
        </tr>
      </thead>
      <tbody className="bg-white ">
        {tags.map((tag) => (
          <tr key={tag.id} className="hover:bg-gray-100">
            <SimpleCell value={tag.name} onClick={() => onSelect(tag)} />
            <ColorCell value={tag.color} onClick={() => onSelect(tag)} />
            <ButtonsCell onDelete={() => onDelete(tag)} />
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
