import { CheckIcon } from '@heroicons/react/solid';
import { LoadingIcon } from './LoadingIcon';

export const LoadingModal = ({ title, ended = false }) => (
  <div
    className="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      />

      <span
        className="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div className={'flex flex-col items-center'}>
          {!ended ? (
            <LoadingIcon className="animate-spin h-1/6 w-1/6 text-indigo-600" />
          ) : (
            <CheckIcon className="h-1/6 w-1/6 text-green-600" />
          )}

          <div className="mt-3 text-center sm:mt-5">
            <h3
              className="text-lg leading-6 font-medium text-2xl text-gray-900"
              id="modal-title"
            >
              {title}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);
