import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { ChatPage } from './pages/Chat/ChatPage';
import { Loading } from './components/Loading';
import { Settings } from './pages/Settings/Settings';
import { ArchivePage } from './pages/Chat/ArchivePage';

export const App = () => {
  const {user} = useAuthenticator((context) => [context.user]);
  if (!user) return <Loading />;

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={ChatPage} />
        <Route path="/archive" component={ArchivePage} />
        <Route path="/settings" component={Settings} />
      </Switch>
    </Router>
  );
};
