import { useMediaQuery } from 'react-responsive';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Chats } from './Chats';
import { Messages } from './Messages';
import { TripleColumnV2 } from '../../components/layouts/TripleColumnV2';
import { mediaQueries } from '../../config/mediaQueries';
import { useSidebar } from '../../contexts/SidebarContext';
import { ChatContext } from '../../contexts/ChatProvider';

export const ChatPage = ({ loadArchive }) => {
  const {
    reload,
    chats,
    archived,
    currentChat,
    currentArchived,
    setCurrentChat,
    setCurrentArchived,
    closeChat,
    toggleTag,
    sendMessage,
  } = useContext(ChatContext);

  const isLg = useMediaQuery({ query: mediaQueries.lg });

  const { showSidebar } = useSidebar();
  const [showMessages, setShowMessages] = useState(false);

  const location = useLocation();

  useEffect(() => {
    reload();
  }, [location]);

  const chatsView = (
    <Chats
      chats={loadArchive ? archived : chats}
      currentChat={loadArchive ? currentArchived : currentChat}
      setCurrentChat={loadArchive ? setCurrentArchived : setCurrentChat}
      loadArchive={loadArchive}
      onSelect={() => {
        setShowMessages(true);
        showSidebar(false);
      }}
    />
  );

  const messagesView = (
    <Messages
      chats={loadArchive ? archived : chats}
      currentChat={loadArchive ? currentArchived : currentChat}
      closeChat={loadArchive ? null : closeChat}
      toggleTag={toggleTag}
      sendMessage={sendMessage}
      onBack={() => {
        setShowMessages(false);
        showSidebar(true);
      }}
    />
  );

  if (isLg) {
    return (
      <TripleColumnV2>
        <aside className="block flex-shrink-0 h-full">
          <div className="h-full relative flex flex-col w-96 bg-white overflow-y-auto">
            {chatsView}
          </div>
        </aside>

        <section className="flex-1 h-full flex flex-col overflow-y-auto overflow-x-hidden">
          {messagesView}
        </section>
      </TripleColumnV2>
    );
  }

  return (
    <TripleColumnV2>
      <section className="flex-1 h-full flex flex-col overflow-y-auto overflow-x-hidden">
        {showMessages ? messagesView : chatsView}
      </section>
    </TripleColumnV2>
  );
};
