import { forwardRef } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';

const Button = ({ name, Icon, action }) => (
  <button
    className="text-gray-700 group flex items-center px-4 py-2 text-sm w-full"
    role="menuitem"
    tabIndex="-1"
    id="menu-item-0"
    onClick={action || (() => {})}
  >
    <Icon className={'mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'} />
    <span>{name}</span>
  </button>
);

const ButtonGroup = ({ buttons }) => (
  <div className="py-1" role="none">
    {buttons
      .filter(({ action }) => !!action)
      .map((button, index) => (
        <Button
          key={index}
          name={button.name}
          Icon={button.icon}
          action={button.action}
        />
      ))}
  </div>
);

export const Dropdown = forwardRef(({ groups, open, setOpen }, ref) => (
  <>
    <button
      className={'p-2 rounded-lg hover:bg-indigo-900 active:bg-indigo-900'}
      onClick={() => setOpen(true)}
    >
      <DotsVerticalIcon className={'h-6 text-gray-100'} />
    </button>
    {open && (
      <div
        ref={ref}
        className={`origin-top-right absolute right-0 w-56 rounded-md shadow-lg 
      bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100
      focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        {groups.map((buttons, index) => (
          <ButtonGroup key={index} buttons={buttons} />
        ))}
      </div>
    )}
  </>
));
Dropdown.displayName = 'Dropdown';
