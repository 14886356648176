import { ChatHeader } from './fragments/ChatHeader';
import { Chat } from './fragments/Chat';

export const Chats = ({
  chats,
  currentChat,
  setCurrentChat,
  loadArchive,
  onSelect,
}) => {
  const onSelectWrapper = (...args) => {
    setCurrentChat(...args);
    onSelect();
  };

  return (
    <div className="flex flex-col h-full">
      <ChatHeader loadArchive={loadArchive} />
      <ul className="flex-1 overflow-y-auto divide-y divide-gray-300 border-r border-gray-300">
        {[...chats.keys()].map((key) => (
          <Chat
            key={key}
            item={chats.get(key)}
            active={key === currentChat}
            onSelect={onSelectWrapper}
          />
        ))}
      </ul>
    </div>
  );
};
