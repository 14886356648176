import { useMemo } from 'react';
import { useAxios } from './useAxios';

export const useTagApi = () => {
  const axios = useAxios();

  return useMemo(() => {
    const getAll = async () => {
      try {
        const response = await axios.get(`/tags`);
        return response.data.tags;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const create = async ({ nombre, color }) => {
      try {
        const response = await axios.post(`/tags`, {
          name: nombre,
          color: color,
        });
        return response.data.tag;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const edit = async ({ id, nombre, color }) => {
      try {
        const response = await axios.put(`/tags/${id}`, {
          name: nombre,
          color: color,
        });
        return response.data.tag;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const remove = async ({ id }) => {
      try {
        const response = await axios.delete(`/tags/${id}`);
        return response.data;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    return { getAll, create, edit, remove };
  }, [axios]);
};
