import axios from 'axios';
import { useMemo } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const useAxios = () => {
  const {user} = useAuthenticator(context => [context.user]);
  const token = user?.signInUserSession.accessToken.jwtToken ?? '';

  return useMemo(
    () =>
      axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [token],
  );
};
