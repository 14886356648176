import { useCallback, useEffect, useState } from 'react';
import { useChatApi } from '../../../hooks/useChatApi';

const Tag = ({ tag, onChangeTag }) => (
  <div className={'flex items-center'}>
    <label
      htmlFor={tag.name}
      className={`w-full whitespace-nowrap cursor-pointer`}
    >
      <input
        id={tag.name}
        type={'checkbox'}
        checked={tag.selected}
        onChange={() => {
          onChangeTag(tag);
        }}
      />
      <span
        className={`inline-flex items-center px-3 py-0.5 rounded-lg 
                    text-sm font-medium select-none mx-2
                    whitespace-normal`}
        style={{ backgroundColor: tag.color }}
      >
        {tag.name}
      </span>
    </label>
  </div>
);

export const TagSidebar = ({ chat, toggleTag }) => {
  const chatApi = useChatApi();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const selectedAsId = chat?.tags?.map((tag) => tag.id) || [];

    chatApi.getTags().then((response) => {
      const mapped = response.map((tag) => ({
        ...tag,
        selected: selectedAsId.includes(tag.id),
      }));

      setTags(mapped);
    });
  }, [chat, chatApi]);

  const onChangeTag = useCallback(async (tag) => {
    toggleTag({ chat, tag });
  }, []);

  return (
    <div
      className={
        'relative flex flex-col right-0 h-full p-2 border-gray-200 border-l w-96'
      }
    >
      <h1 className={'font-medium text-2xl mb-2'}>Etiquetas</h1>
      <div className={'m-2 space-y-2'}>
        {tags.map((tag) => (
          <Tag key={tag.id} tag={tag} onChangeTag={onChangeTag} />
        ))}
      </div>
    </div>
  );
};
