import { useMemo, useState } from 'react';
import { TagIcon } from '@heroicons/react/outline';
import { useMediaQuery } from 'react-responsive';
import { TripleColumnV2 } from '../../components/layouts/TripleColumnV2';
import { TagSettings } from './Tags/TagSettings';
import { HeaderRight } from '../../components/layouts/HeaderRight';
import { HeaderLeft } from '../../components/layouts/HeaderLeft';
import { useSidebar } from '../../contexts/SidebarContext';
import { mediaQueries } from '../../config/mediaQueries';

const MenuLink = ({ name, Icon, title, subtitle, selected, onSelect }) => (
  <li
    role="button"
    className={`px-6 py-4 cursor-pointer hover:bg-gray-100 ${
      selected && 'bg-gray-100'
    }`}
    onClick={() => onSelect(name)}
  >
    <div className={'flex items-center'}>
      <Icon className={'flex-shrink-0 h-6 w-6 mt-0.5 text-gray-400'} />
      <div className={'ml-3 text-sm'}>
        <h4 className="font-medium text-gray-900">{title}</h4>
        <p className={'mt-1 text-gray-500'}>{subtitle}</p>
      </div>
    </div>
  </li>
);

const Menus = ({ navigateTo }) => {
  const [selected, setSelected] = useState(null);

  const navigate = (name) => {
    navigateTo(name);
    setSelected(name);
  };

  return (
    <div>
      <HeaderLeft title={'Configuración'} />
      <div className="flex flex-col h-screen">
        <ul className="flex-1 divide-y divide-gray-300 border-r border-gray-300 overflow-y-auto">
          <MenuLink
            name={'etiquetas'}
            Icon={TagIcon}
            title={'Etiquetas'}
            subtitle={'Agregar o quitar etiquetas para utilizar en chats'}
            onSelect={() => navigate('etiquetas')}
            selected={selected === 'etiquetas'}
          />
        </ul>
      </div>
    </div>
  );
};

export const Settings = () => {
  const isLg = useMediaQuery({ query: mediaQueries.lg });
  const { showSidebar } = useSidebar();
  const [showSettings, setShowSettings] = useState(false);
  const [current, setCurrent] = useState(null);

  const navigate = (name) => {
    setCurrent(name);
    setShowSettings(true);
    showSidebar(false);
  };

  const component = useMemo(() => {
    if (current === 'etiquetas')
      return (
        <TagSettings
          onBack={() => {
            setShowSettings(false);
            showSidebar(true);
          }}
        />
      );
    return <HeaderRight title={''} />;
  }, [current]);

  const menus = <Menus navigateTo={navigate} />;

  if (isLg) {
    return (
      <TripleColumnV2>
        <aside className="block flex-shrink-0 h-full">{menus}</aside>

        <section className="flex-1 h-full flex flex-col overflow-y-auto overflow-x-hidden">
          {component}
        </section>
      </TripleColumnV2>
    );
  }

  return (
    <TripleColumnV2>
      <section className="flex-1 h-full flex flex-col overflow-y-auto overflow-x-hidden">
        {showSettings ? component : menus}
      </section>
    </TripleColumnV2>
  );
};
