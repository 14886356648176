import { useMemo } from 'react';
import { useAxios } from './useAxios';

export const useChatApi = () => {
  const axios = useAxios();

  return useMemo(() => {
    const getAll = async () => {
      try {
        const response = await axios.get('/chats');
        return response.data?.chats;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const getArchived = async () => {
      try {
        const response = await axios.get('/chats', {
          params: { archived: true },
        });
        return response.data?.chats;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const getMessages = async (chat, page) => {
      try {
        const response = await axios.get(`/chats/${chat}/messages`, {
          params: { page },
        });
        return response.data;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const closeChat = async (chat) => {
      try {
        await axios.put(`/chats/${chat.id}/close`);
        return { error: false };
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const getTags = async () => {
      try {
        const response = await axios.get(`/tags`);
        return response.data.tags;
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const addTag = async (chat, tag) => {
      try {
        await axios.put(`/chats/${chat}/tags/${tag}`);
        return { error: false };
      } catch (error) {
        return { error: true, message: error };
      }
    };

    const removeTag = async (chat, tag) => {
      try {
        await axios.delete(`/chats/${chat}/tags/${tag}`);
        return { error: false };
      } catch (error) {
        return { error: true, message: error };
      }
    };

    return {
      getAll,
      getArchived,
      getMessages,
      getTags,
      addTag,
      removeTag,
      closeChat,
    };
  }, [axios]);
};
