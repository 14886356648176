import './index.css';
import '@aws-amplify/ui-react/dist/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { AmplifyProvider } from './contexts/AmplifyProvider';
import { SocketProvider } from './contexts/SocketProvider';
import { ChatProvider } from './contexts/ChatProvider';
import { SidebarProvider } from './contexts/SidebarContext';
import { App } from './App';

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
       <SocketProvider>
        <ChatProvider>
          <SidebarProvider>
            <App />
          </SidebarProvider>
        </ChatProvider>
       </SocketProvider>
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
