import io from 'socket.io-client';
import { createContext, useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const [socket, setSocket] = useState(null);
  const [online, setOnline] = useState(false);

  useEffect(() => {
    if (!user) return;

    const newSocket = io(process.env.REACT_APP_API_HOST, {
      query: {
        token: user.signInUserSession.accessToken.jwtToken,
      },
    });

    setSocket(newSocket);
  }, [user]);

  useEffect(() => {
    if (!socket) {
      setOnline(false);
      return null;
    }

    socket.on('connect', () => {
      setOnline(socket.connected);
    });

    return () => socket.off('connect');
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      setOnline(false);
      return null;
    }

    socket.on('disconnect', () => {
      setOnline(socket.connected);
    });

    return () => socket.off('disconnect');
  }, [socket]);

  useEffect(() => {
    socket?.onAny((...args) => console.log('EVENT', ...args));
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket, online }}>
      {children}
    </SocketContext.Provider>
  );
};
