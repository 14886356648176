import { InboxIcon, ArchiveIcon, CogIcon } from '@heroicons/react/outline';
import { Link as A, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useMemo } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useSidebar } from '../../contexts/SidebarContext';
import { mediaQueries } from '../../config/mediaQueries';

const Logo = () => (
  <div className="bg-indigo-700 py-4 flex items-center justify-center">
    <img
      className="h-8 w-auto"
      src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
      alt="Workflow"
    />
  </div>
);

const Link = ({ icon: Icon, href, name }) => {
  const location = useLocation();

  return (
    <A
      to={href}
      className={`flex items-center p-4 rounded-lg text-indigo-200 ${
        location?.pathname !== href
          ? 'text-indigo-200'
          : 'text-white bg-indigo-700'
      } hover:bg-indigo-700`}
    >
      <Icon className="h-6 w-6" aria-hidden="true" />
      <span className="sr-only">{name}</span>
    </A>
  );
};

const UserIcon = () => {
  const auth = useAuthenticator((c) => [c.user]);

  const user = useMemo(
    () => ({
      name: auth.user.username,
      imageUrl: `https://avatars.dicebear.com/api/identicon/${auth.user.username}.svg?scale=75`,
    }),
    [auth],
  );

  return (
    <button
      className="flex-shrink-0 w-full mb-6"
      onClick={() => auth.signOut()}
    >
      <img
        className="block mx-auto h-10 w-10 rounded-full bg-indigo-50"
        src={user.imageUrl}
        alt=""
      />
    </button>
  );
};

const Sidebar = ({ children }) => {
  const sidebar = useSidebar();
  const isLg = useMediaQuery({ query: mediaQueries.lg });

  return (
    <div
      className={`${sidebar.isShown || isLg ? 'flex' : 'hidden'} flex-shrink-0`}
    >
      <div className={'flex flex-col w-20 bg-indigo-600'}>
        <Logo />
        <div className={'flex flex-col justify-between h-full'}>
          <nav className={'py-6 flex flex-col items-center space-y-3'}>
            {children}
          </nav>
          <UserIcon />
        </div>
      </div>
    </div>
  );
};

export const TripleColumnV2 = ({ children }) => (
  <div className="h-full flex flex-col">
    <main className="flex-1 flex overflow-hidden">
      <Sidebar>
        <Link icon={InboxIcon} href={'/'} name={'Chat'} />
        <Link icon={ArchiveIcon} href={'/archive'} name={'ArchivePage.js'} />
        <Link icon={CogIcon} href={'/settings'} name={'Settings'} />
      </Sidebar>
      {children}
    </main>
  </div>
);
