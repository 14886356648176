import { useMemo, useState } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { MessagesHeader } from './fragments/MessagesHeader';
import { MessageInput } from './fragments/MessageInput';
import { LoadingModal } from '../../components/LoadingModal';
import { totalWait } from '../../utils/totalWait';
import { wait } from '../../utils/wait';
import { TagSidebar } from './fragments/TagSidebar';
import { Message } from './fragments/MessageTypes';

export const Messages = ({
  chats,
  currentChat,
  closeChat,
  onBack,
  toggleTag,
  sendMessage,
}) => {
  const { user } = useAuthenticator(context => [context.user]);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loadingEnded, setLoadingEnded] = useState(false);

  const [showSidebar, setShowSidebar] = useState(false);

  const chat = useMemo(() => {
    if (!currentChat) return null;
    return chats.get(currentChat);
  }, [chats, currentChat]);

  const messages = useMemo(() => {
    if (!chat) return [];
    return chat.messages;
  }, [chat]);

  const onClose = async () => {
    setModalMessage('Cerrando el requerimiento');
    setShowModal(true);
    await totalWait(closeChat(chats.get(currentChat)), 1000);
    setLoadingEnded(true);
    await wait(1000);
    setShowModal(false);
    setLoadingEnded(false);
    onBack();
  };

  const onTag = () => {
    setShowSidebar((old) => !old);
  };

  return (
    <div className="flex flex-col h-full">
      <MessagesHeader
        chat={chat}
        onBack={onBack}
        onClose={closeChat ? onClose : null}
        onTag={onTag}
      />
      <div className={'relative flex-1 flex overflow-y-hidden'}>
        <div className="flex-1 flex flex-col-reverse overflow-y-auto px-6">
          {messages.map((message) => (
            <Message
              key={message.id}
              message={message}
              chat={chat}
              userId={user.signInUserSession.accessToken.jwtToken}
            />
          ))}
        </div>
        {showSidebar && <TagSidebar chat={chat} toggleTag={toggleTag} />}
      </div>
      <MessageInput currentChat={currentChat} sendMessage={sendMessage} />
      {showModal && <LoadingModal title={modalMessage} ended={loadingEnded} />}
    </div>
  );
};
