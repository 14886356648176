import { trim } from '../../../utils/trim';
import { ChatIcon } from './ChatIcon';

const Encargado = ({ item }) => (
  <span
    className={`-mt-2 px-1.5 text-sm text-gray-700 
     rounded bg-indigo-100`}
  >
    {item.user.name}
  </span>
);

const Fuente = ({ item }) => (
  <span
    className={`-mt-2 px-1.5 text-sm text-gray-700 
     rounded bg-red-100`}
  >
    {item.external_user.to}
  </span>
);

export const Chat = ({ item, active, onSelect }) => (
  <li
    role="button"
    key={item.id}
    className={`px-6 py-4 cursor-pointer hover:bg-gray-100 ${
      active ? 'bg-gray-100' : ''
    }`}
    onClick={() => onSelect(item.id)}
  >
    <div className="flex">
      <ChatIcon chat={item} />
      <div>
        <span className="text-lg font-medium block">
          {item.external_user.name}
        </span>
        <div className={'space-x-2'}>
          <Encargado item={item} />
          <Fuente item={item} />
        </div>
        <p className="mt-1">{trim(item.messages[0]?.content, 30)}</p>
      </div>
    </div>
  </li>
);
