import { createContext, useEffect } from 'react';
import Amplify from 'aws-amplify';
import {
  Authenticator,
  AmplifyProvider as BaseAmplifyProvider,
  useTheme, View, Image, Heading,
} from '@aws-amplify/ui-react';

const AmplifyContext = createContext();

const components = { // eslint-disable-line object-shorthand
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign='center' padding={tokens.space.large}>
        <Image
          alt='Amplify logo'
          src='/logo.png'
        />
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={4}>
          Bienvenido a Omnicanalidad
        </Heading>
      );
    },
  },
};

export const AmplifyProvider = ({ children }) => {
  const configure = () => {
    Amplify.configure({
      Auth: {
        userPoolId: 'us-east-1_XvtsBslhJ',
        region: 'us-east-1',
        userPoolWebClientId: '2dsevk02bhbq5g62f8vu5iki6e',
      },
    });
  };

  useEffect(configure, []);

  return (
    <AmplifyContext.Provider value={{}}>
      <BaseAmplifyProvider>
        <Authenticator hideSignUp={true} components={components}>
          {children}
        </Authenticator>
      </BaseAmplifyProvider>
    </AmplifyContext.Provider>
  );
};
