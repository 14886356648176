import { ArchiveIcon, ArrowLeftIcon, TagIcon } from '@heroicons/react/outline';
import { withClickOutside } from '../../../components/withClickOutside';
import { Dropdown } from '../../../components/Dropdown';
import { ChatIcon } from './ChatIcon';

export const MessagesHeader = ({ chat, onClose, onTag, onBack }) => {
  if (!chat) {
    return (
      <div className="flex px-6 h-16 items-center bg-indigo-600">
        <button
          className={
            'p-2 ml-4 rounded-lg hover:bg-indigo-900 active:bg-indigo-900 lg:hidden '
          }
          onClick={onBack}
        >
          <ArrowLeftIcon className={'h-6 text-gray-100'} />
        </button>
      </div>
    );
  }

  const WithClickOutsideDropdown = withClickOutside(Dropdown);

  return (
    <div className="flex justify-between h-16 items-center bg-indigo-700 lg:bg-indigo-600 z-10">
      <div className={'flex items-center'}>
        <button
          className={
            'p-2 ml-4 rounded-lg hover:bg-indigo-900 active:bg-indigo-900 lg:hidden '
          }
          onClick={onBack}
        >
          <ArrowLeftIcon className={'h-6 text-gray-100'} />
        </button>
        <div className={'ml-4'}>
          <ChatIcon chat={chat} size={10} />
        </div>
        <h4 className="text-2xl font-medium text-white">
          {chat.external_user.name}
        </h4>
      </div>
      <div className={'p-2'}>
        <WithClickOutsideDropdown
          groups={[
            [
              {
                name: 'Etiquetar',
                icon: TagIcon,
                action: onTag,
              },
              {
                name: 'Cerrar Requerimiento',
                icon: ArchiveIcon,
                action: onClose,
              },
            ],
          ]}
        />
      </div>
    </div>
  );
};
