const MessageBase = ({
  message,
  chat,
  containerClassName,
  userClassName,
  contentClassName,
}) => (
  <div
    className={`flex flex-col max-w-3/4 rounded-lg border border-gray-300 pb-3 pt-2 px-3 my-2 break-words whitespace-pre-line ${containerClassName}`}
  >
    <span className={`text-xs font-bold ${userClassName}`}>
      {message.user?.name ?? chat.external_user.name}
    </span>
    {(chat.external_user.source.name === 'Contacto' ||
      chat.external_user.source.name === 'Correo') && (
      <p
        className={contentClassName}
        dangerouslySetInnerHTML={{ __html: message.content }}
      />
    )}
    {chat.external_user.source.name !== 'Contacto' &&
      chat.external_user.source.name !== 'Correo' && (
        <p className={contentClassName}>{message.content}</p>
      )}
  </div>
);

const MessageExternal = ({ message, chat }) => (
  <MessageBase
    containerClassName={'bg-white rounded-tl-none self-start'}
    message={message}
    chat={chat}
  />
);

const MessageInternal = ({ message, chat }) => (
  <MessageBase
    containerClassName={'bg-red-500 bg-opacity-20 rounded-tl-none self-start'}
    message={message}
    chat={chat}
  />
);

const MessageMine = ({ message, chat }) => (
  <MessageBase
    containerClassName={'bg-green-500 bg-opacity-5 rounded-tr-none self-end'}
    message={message}
    chat={chat}
  />
);

export const Message = ({ message, chat, userId }) => {
  if (message.user && message.user?.kauth === userId) {
    return <MessageMine key={message.id} message={message} chat={chat} />;
  }

  if (message.user) {
    return <MessageInternal key={message.id} message={message} chat={chat} />;
  }

  return <MessageExternal key={message.id} message={message} chat={chat} />;
};
