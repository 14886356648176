import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { Fragment, useRef } from 'react';

export const MessageInput = ({ currentChat, sendMessage }) => {
  const inputRef = useRef();

  const onSendMessage = () => {
    const message = inputRef.current.innerText;
    if (message.trim() === '') return;

    sendMessage(currentChat, message);
    inputRef.current.innerText = '';
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSendMessage();
    }
  };

  if (!currentChat) {
    return <Fragment />;
  }

  return (
    <div className="py-4 px-6 flex flex-shrink-0 flex-row items-center bg-white border-t">
      <div
        className="flex-1 rounded-lg border-gray-300 overflow-x-auto border border-gray-400 px-3 py-2"
        style={{ maxHeight: '10em' }}
        onKeyPress={onKeyPress}
        contentEditable={true}
        ref={inputRef}
      />
      <button
        className={`rounded-full h-10 w-10 ml-4 
                    bg-green-500 hover:bg-green-600 active:bg-green-700 
                    flex justify-center items-center flex-shrink-0`}
        onClick={sendMessage}
      >
        <PaperAirplaneIcon className="h-5 w-5 transform rotate-90 text-white" />
      </button>
    </div>
  );
};
