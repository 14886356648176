import { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isShown, showSidebar] = useState(true);

  return (
    <SidebarContext.Provider value={{ isShown, showSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  return { showSidebar: context.showSidebar, isShown: context.isShown };
};
