import { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useChatApi } from '../hooks/useChatApi';
import { SocketContext } from './SocketProvider';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const { socket } = useContext(SocketContext);
  const chatApi = useChatApi();

  const [all, setAll] = useState(new Map());

  const chats = useMemo(
    () => new Map([...all].filter(([, v]) => !v.archived)),
    [all],
  );
  const archived = useMemo(
    () => new Map([...all].filter(([, v]) => v.archived)),
    [all],
  );

  const [currentChat, setCurrentChat] = useState(null);
  const [currentArchived, setCurrentArchived] = useState(null);

  const load = useCallback(() => {
    if (!chatApi || !user) {
      setAll(new Map());
    } else {
      chatApi.getAll().then((response) => {
        if (response.error) {
          setAll(new Map());
          return;
        }

        const map = new Map();

        response.forEach((chat) => {
          map.set(chat.id, chat);
        });

        setAll(map);
      });
    }
  }, [chatApi, user]);

  // Cada vez que se recibe un nuevo mensaje, este se agrega al principio de,
  // la lista bajo la key correspondiente en chats
  const receiveMessage = useCallback(
    (message) => {
      setAll((old) => {
        const copy = new Map(old);
        const chat = copy.get(message.chat_id);
        if (!chat) return old;

        copy.set(message.chat_id, {
          ...chat,
          messages: [message, ...chat.messages],
        });

        return copy;
      });
    },
    [setAll],
  );

  const newChat = useCallback(
    (chat) => {
      setAll((old) => {
        const copy = new Map(old);
        copy.set(chat.id, chat);
        return copy;
      });
    },
    [setAll],
  );

  const removeChat = useCallback(
    (id) => {
      setAll((old) => {
        const copy = new Map(old);
        copy.set(id, {
          ...copy.get(id),
          archived: true,
        });
        return copy;
      });
    },
    [setAll],
  );

  const sendMessage = useCallback(
    (chat, message) => {
      const payload = {
        from: user.signInUserSession.accessToken.payload.sub,
        to: chat,
        content: message,
      };
      socket?.emit('send-message', payload);
    },
    [socket, user],
  );

  const closeChat = async (chat) => {
    const response = await chatApi.closeChat(chat);
    if (response.error) return response;
    return null;
  };

  const toggleTag = async ({ chat, tag }) => {
    if (tag.selected) {
      await chatApi.removeTag(chat.id, tag.id);
    } else {
      await chatApi.addTag(chat.id, tag.id);
    }

    setAll((old) => {
      const oldChat = old.get(chat.id);
      const copy = new Map(old);

      let tags;

      if (tag.selected) {
        tags = [...oldChat.tags.filter(({ id }) => id !== tag.id)];
      } else {
        tags = [...oldChat.tags, tag];
      }

      copy.set(chat.id, { ...oldChat, tags });

      return copy;
    });
  };

  useEffect(load, [load]);

  useEffect(() => {
    socket?.on('receive-message', receiveMessage);
    return () => socket?.off('receive-message', receiveMessage);
  }, [socket, receiveMessage]);

  useEffect(() => {
    socket?.on('new-chat', newChat);
    return () => socket?.off('new-chat', newChat);
  }, [socket, newChat]);

  useEffect(() => {
    socket?.on('remove-chat', removeChat);
    return () => socket?.off('remove-chat', removeChat);
  });

  return (
    <ChatContext.Provider
      value={{
        chats: chats,
        archived: archived,
        currentChat: currentChat,
        currentArchived: currentArchived,
        sendMessage: sendMessage,
        setCurrentChat: setCurrentChat,
        setCurrentArchived: setCurrentArchived,
        closeChat: closeChat,
        toggleTag: toggleTag,
        reload: load,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
